<template>
  <div class="h-full">
    <div class="cust_card p-2 m-2 -mt-2">
      <div>
        <p class="heading-3 text-gray4">Setting</p>
      </div>
    </div>
    <div :style="`min-height:` +  cardHeight + `px;`" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 pl-2">
      <div @click="$router.push({name: panel.name})" style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" v-for=" (panel, index) in adminPanels" :key="index">
        <p class="text-right text-primary2 heading-3">
          {{panel.meta.title}}
        </p>
        <div class="card_icon bg-primary2 flex justify-center items-center">
        <i style="font-size: 36px; color: white;" :class="panel.meta.icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {AdminPanel} from '@/View/Users/router/index.js'
export default {
  data () {
    return {
      adminPanels: [],
      cardHeight: window.innerHeight - 220
    }
  },
  created() {
    document.title= 'Dashboard'
  },
  mounted () {
    this.getAdminPanel()
  },
  methods: {
    getAdminPanel () {
      AdminPanel.children.forEach(val => {
        if (val.name !== 'ProfileDetail') {
          this.adminPanels.push(val)
        }
      })
    }
  }
}
</script>
<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
      position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 80px!important;
    height: 80px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
